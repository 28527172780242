<template>
  <div class="page-content">
    <page-breadcrumb title="AI Tutor - Error Feedback Detail" class="mb-2" />
    <div class="d-flex align-items-center">
      <form-generator class="flex-grow-1" :model="query" :schema="query_schema" />
      <loading-button class="ml-2 mt-1" @click="$refs.item_list.getList()">Get Report</loading-button>
    </div>

    <data-table-ssr id="item_list" ref="item_list" :limit-base="10"
      :get-list-fn="getList" :columns="item_fields"
    />
  </div>
</template>

<script>
import { objectToURLQuery, safeGet } from '@core/utils/index';
import service from '../service';
import useURLBind from '@/@core/comp-functions/useURLBind';

const query_schema = [
  {
    cols: 6,
    fields: [{ field: 'start_time', label: 'Start time', input_type: 'date' }],
  },
  {
    cols: 6,
    fields: [{ field: 'end_time', label: 'End time', input_type: 'date' }],
  },
];

const feedback_problems = [];

const item_fields = [
  { label: 'Lesson', field: 'lesson_title' },
  { label: 'User Email', field: 'user_email' },
  { label: 'Reported Sentence', field: 'sentence_content' },
  { label: 'Feedback', field: 'problem', input_type: 'multiselect', options: feedback_problems },
  { label: 'Report Time', field: 'createdAt', input_type: 'datetime' },
];

export default {
  setup(_, ctx) {
    let { query } = useURLBind({ start_time: null, end_time: null }, ctx);
    return { query };
  },
  data(){
    return{
      item_fields,
      total: 0,
      query_schema,
    }
  },
  methods: {
    async getList({ limit, page }) {
      let response = await service.getList({
        query: objectToURLQuery({ ...this.query }),
        page,
        limit,
        populate: { path: 'topic', select: 'name' },
      });
      let list = []; let total = 0;
      if (response.data.type === "DATA") {
        list = response.data.data.list;
        total = response.data.data.total;

        this.query.start_time = response.data.data.start_time;
        this.query.end_time = response.data.data.end_time;

        let problems_list = safeGet(() => {
          let problems = response.data.data.meta['FEEDBACK_PROBLEM'];
          let list = [];
          for (const [key, value] of Object.entries(problems)) {
            list.push({
              text: value,
              value: Number(key),
              variant: 'danger',
            });
          }
          return list;
        }) || [];

        feedback_problems.length = 0;
        feedback_problems.push(...problems_list);
      }
      return { list, total };
    },

  },
};
</script>
